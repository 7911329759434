<template>
  <ion-page id="rolechats-page" class="page scrollable bg-transparent pt-0 px-0">
    <div v-if="isLoading" class="d-flex justify-content-center my-4">
      <ChLoading size="sm" class="spinner" />
    </div>

    <template v-else>
      <div class="bg-white">
        <h1 class="mb-4 text-center">
          <strong>{{ rcRequest?.request_to?.username }} / {{ rcRequest?.request_from?.username }} Rolechat</strong>
        </h1>
      </div>

      <div class="p-3 request-content d-flex justify-content-center">
        <div :class="isMobSmallScreen ? 'w-100' : 'w-75'">
          <div class="d-flex">
            <strong class="clickable text-black"
              >Narrator ({{
                rcRequest?.rolechat_starter ? rcRequest?.request_to?.username : rcRequest?.request_from?.username
              }})
            </strong>

            <small class="ml-auto">{{ formatDescDateTime(rcRequest?.created) }}</small>
          </div>
          <div class="mt-2 px-0">
            <span v-if="rcRequest?.rolechat_starter">{{ rcRequest?.rolechat_starter?.description }}</span>
            <div v-else>
              <p class="msg-inner">{{ rcRequest?.message }}</p>
              <strong>Request For:</strong>
              {{ get(rcRequest, 'character.info.name', 'Unknown') }}
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white d-flex justify-content-center py-2">
        <div>
          <div class="bg-white d-flex justify-content-center">
            <router-link
              :to="{
                name: 'profile',
                params: { username: rcRequest?.request_from?.username },
              }"
            >
              <ion-button class="submit-btn">
                <span>View Profile</span>
              </ion-button>
            </router-link>
          </div>
          <div class="d-flex justify-content-between">
            <ion-button
              class="submit-btn"
              :disabled="isResponding"
              color="danger"
              @click.prevent="updateReqStatus('denied')"
            >
              <span>Decline</span>
            </ion-button>

            <ion-button
              class="submit-btn ml-3"
              :disabled="isResponding"
              color="success"
              @click.prevent="updateReqStatus('accepted')"
            >
              <span>Accept</span>
            </ion-button>
          </div>
        </div>
      </div>
    </template>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ChatMsgItem from './ChatMsgItem.vue';
import ChatNarrMsgItem from './ChatNarrMsgItem.vue';
import PostChatMessage from './PostChatMessage.vue';
import { getRolechatRequest, updateRolechatReqStatus } from '@/shared/actions/rolechats';
import { formatDescDateTime } from '@/shared/utils/dateTime';
import { toast } from '@/shared/native';
import constants from '@/shared/statics/constants';

@Options({
  name: 'RolechatRequestDetails',
  components: { ChatMsgItem, ChatNarrMsgItem, PostChatMessage },
})
export default class RolechatRequestDetails extends Vue {
  public isLoading = true;
  public isResponding = false;

  get = get;
  formatDescDateTime = formatDescDateTime;

  rcRequest = {};

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public async fetchRolechatRequest() {
    const router = useRouter();
    this.isLoading = true;

    try {
      this.rcRequest = await getRolechatRequest(router.currentRoute.value.params.id);
    } catch (_err) {
      await toast.show("Couldn't find the request", 'nonative', 'danger');
      router.go(-1);
    }

    this.isLoading = false;
  }

  public async updateReqStatus(status: string) {
    this.isResponding = true;
    try {
      const router = useRouter();
      await updateRolechatReqStatus(router.currentRoute.value.params.id, status);
      router.push({
        name: 'rolechats',
        ...(status === constants.rolechatReqStatuses.denied && {
          query: { tab: 'requests' },
        }),
      });
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }

    this.isResponding = false;
  }

  public goToDiscover() {
    this.$emit('changeTab', { value: 'discover' });
  }

  mounted() {
    this.fetchRolechatRequest();
  }
}
</script>

<style scoped lang="sass">
.unread-chat
  font-weight: bold
.spinner
  width: 40px
  height: 40px

.submit-btn
  width: 120px

.request-content
  height: calc(100vh - 262px)
  overflow: auto
.msg-inner
  white-space: pre-line
</style>
